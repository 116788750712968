import Highcharts from 'highcharts/highstock';
import highChartZoom from 'highcharts/modules/mouse-wheel-zoom';
import patternFill from 'highcharts/modules/pattern-fill';
import noData from 'highcharts/modules/no-data-to-display';
import SankeyModule from 'highcharts/modules/sankey';

import HighchartsReact, {
  type HighchartsReactRefObject,
} from 'highcharts-react-official';
import { forwardRef } from 'react';

export enum HChartsModules {
  SANKEY = 'sankey',
  DEFAULT = 'default',
  ZOOM = 'zoom',
  PATTERN_FILL = 'pattern_fill',
  NO_DATA_TO_DISPLAY = 'no_data_to_display',
}

export interface HChartOptionsProps {
  options: Highcharts.Options;
  id?: string;
  constructorType?: string;
  modules?: [(highcharts: typeof Highcharts) => void];
  callback?: (e: any) => void;
}
highChartZoom(Highcharts);
patternFill(Highcharts);
noData(Highcharts);
SankeyModule(Highcharts);

const HChart = forwardRef<HighchartsReactRefObject, HChartOptionsProps>(
  (
    { options, id, constructorType = 'stockChart', modules = [], callback },
    ref
  ) => {
    // useMemo(() => {
    //   if (modules.length > 0) {
    //     modules.forEach((module) => {
    //       module(Highcharts);
    //     });
    //     return;
    //   }
    //   console.log('rodou aqui');
    //   highChartZoom(Highcharts);
    //   patternFill(Highcharts);
    //   noData(Highcharts);
    // }, [modules]);

    const optionsWithoutCredits = {
      ...options,
      credits: {
        enabled: false,
      },
    };

    return (
      <div>
        <HighchartsReact
          id={id}
          ref={ref}
          highcharts={Highcharts}
          options={optionsWithoutCredits}
          constructorType={constructorType}
          credits={false}
          callback={callback}
        />
      </div>
    );
  }
);

export default HChart;
