import clsx from 'clsx';
import { GRADE } from 'pages/BuildingViewPage/BuildingViewPage';
import { type FC } from 'react';

interface BuildingViewMeterChartProps {
  meter?: { id?: number; name?: string };
}

const BuildingViewMeterChart: FC<BuildingViewMeterChartProps> = ({ meter }) => {
  const effectiveness: string = GRADE.NEGATIVE;
  return (
    <div className="flex flex-col gap-4">
      <div className="flex gap-4 items-center font-bold">
        <p>METER:</p>
        {meter?.id ? (
          <div
            className={clsx(
              'px-2 py-1.5 rounded-full bg-opacity-25',
              effectiveness === GRADE.POSITIVE && 'bg-success',
              effectiveness === GRADE.NEGATIVE && 'bg-error'
            )}
          >
            {meter?.name}
          </div>
        ) : (
          <div className="px-2 py-1.5 rounded-full bg-primary-lighter">
            <p>No meter Selected</p>
          </div>
        )}
      </div>
    </div>
  );
};
export default BuildingViewMeterChart;
