import { FDatePickerField } from '@components/form/FDatePickerField';
import { type FC, useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { addDays, subYears } from 'date-fns';
import { FLabel } from '@components/form/FLabel';
import Tippy from '@tippyjs/react';
import { InfoIcon } from '@components/icons/InfoIcon';
import { useTranslation } from 'react-i18next';

interface DatePickerRangeProps {
  label: string;
  tooltipMessage: string;
  startControlName: string;
  endControlName: string;
  yearRangeValidation?: number;
  initialDate?: string;
  disabled?: boolean;
  id?: string;
  containerId?: string;
}

const DatePickerRange: FC<DatePickerRangeProps> = ({
  label,
  tooltipMessage,
  startControlName,
  endControlName,
  yearRangeValidation = 2,
  initialDate,
  disabled = false,
  id,
  containerId,
}) => {
  const { watch, setValue } = useFormContext();
  const { t } = useTranslation();
  const startDate = watch(startControlName);
  const endDate = watch(endControlName);

  const minDate = useMemo(() => {
    if (!startDate) return addDays(new Date(), 1);
    return addDays(startDate, 1);
  }, [startDate]);

  useEffect(() => {
    if (startDate > endDate) {
      setValue(endControlName, null);
    }
  }, [startDate]);

  return (
    <div id={containerId} data-testid={containerId}>
      <div className="flex items-center gap-1">
        <FLabel>{label}</FLabel>
        <Tippy content={tooltipMessage}>
          <span>
            <InfoIcon />
          </span>
        </Tippy>
      </div>
      <div className="flex gap-6">
        <div className="flex-1 max-w-[50%]">
          <FDatePickerField
            id={id ? `${id}-start` : undefined}
            name={startControlName}
            minDate={
              initialDate
                ? new Date(initialDate)
                : subYears(new Date(), yearRangeValidation)
            }
            placeholderText={t('common.placeholders.startDate')}
            disabled={disabled}
          />
        </div>
        <div className="flex-1 max-w-[50%]">
          <FDatePickerField
            id={id ? `${id}-end` : undefined}
            name={endControlName}
            minDate={minDate}
            placeholderText={t('common.placeholders.endDate')}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
};
export default DatePickerRange;
