// import { useFetchBuildingView } from 'api/assets/buildingViewService';
import { type FC, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { BuildingCard } from './components/BuildingCard';
import { BuildingOverallPerformance } from './components/BuildingOverallPerformance';
import { BuildingSankeyChart } from './components/BuildingSankeyChart';
import { BuildingNeedAttentionTable } from './tables/BuildingNeedAttentionTable';
import { MostImportantChangesTable } from './tables/MostImportantChangesTable';
import { CardContainer } from 'layout/Card';
import { SingleSelectInput } from '@components/inputs/SelectInput/SingleSelectInput';
import { BuildingSankeyButton } from './components/BuildingSankeyButton';
import { UtilityTypeEnum } from 'enums/UtilityTypeEnum';
import buildingViewMockData from './mock/buildingViewMock.json';
import { UnitTypeEnum } from 'enums/UnitTypeEnum';
import { BuildingViewMeterChart } from './components/BuildingViewMeterChart';
import { MeasuredVsExpectedChart } from '@components/charts';
import meterMockData from './mock/meterChartMock.json';
import { ChartButton } from '@components/charts/ChartButton';
import { t } from 'i18next';
import { type ChangeTS } from 'interfaces/change/ChangeTS.interface';

export enum GRADE {
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

const CHART_BUTTON_VALUES = {
  MEASURED: 'measured',
  CUSUM: 'cusum',
};

const BuildingViewPage: FC = () => {
  const { id } = useParams();

  // const { data, isFetching } = useFetchBuildingView(id);
  const data = buildingViewMockData;
  const isFetching = false;
  const [selectedSankeyOption, setSelectedSankeyOption] = useState(
    UtilityTypeEnum.ELECTRICITY as string
  );
  const [activeChart, setActiveChart] = useState(CHART_BUTTON_VALUES.MEASURED);
  const [selectedSankeyDropdownOption, setSelectedSankeyDropdownOption] =
    useState(1);
  const [selectedMeter, setSelectedMeter] = useState<{
    id?: number;
    name?: string;
  }>({
    id: undefined,
    name: undefined,
  });

  const sankeyDropdownOptions = [
    { value: 1, name: 'Last year (2024)' },
    { value: 2, name: 'Last month' },
  ];

  const buttonList = [
    {
      label: t('anomaliesDetailsPage.buttons.measuredVsExcepted'),
      value: CHART_BUTTON_VALUES.MEASURED,
      tooltip: t('anomaliesDetailsPage.toolTips.measuredVsExcepted'),
    },
    {
      label: 'CuSUM',
      value: CHART_BUTTON_VALUES.CUSUM,
      tooltip: t('anomaliesDetailsPage.toolTips.CuSUM'),
    },
  ];

  const handleSelectMeter = ({
    meterId,
    meterName,
  }: {
    meterId: number;
    meterName: string;
  }): void => {
    if (selectedMeter.id === meterId) {
      setSelectedMeter({ id: undefined, name: undefined });
      return;
    }
    setSelectedMeter({ id: meterId, name: meterName });
  };
  return (
    <div className="flex flex-col gap-4">
      {/* HEADER */}
      <div className="flex gap-4">
        <BuildingCard asset={data?.asset_impact} isFetching={isFetching} />
        <div className="w-3/12">
          <BuildingOverallPerformance
            building={data?.asset_impact}
            isFetching={isFetching}
          />
        </div>
      </div>
      {/* TABLES */}
      <div className="flex gap-4">
        {/* TOP CHANGES TABLE */}
        <div className="flex w-1/2 flex-col gap-4">
          <div className="flex w-full justify-between items-center my-2">
            <h3 className="font-bold uppercase">TOP 3 DETECTED CHANGES</h3>
            <Link
              to={`/app/operations/anomalies?asset=${id ?? ''}`}
              className="text-base mr-6 text-tertiary-dark"
            >
              See all
            </Link>
          </div>
          <MostImportantChangesTable buildingId={id} />
        </div>
        {/* NEED ATTENTION PROJECTS TABLE */}
        <div className="flex w-1/2 flex-col gap-4">
          <div className="flex justify-between items-center my-2">
            <h3 className="font-bold uppercase">Projects</h3>
            <Link
              to={`/app/management/projects?asset=${id ?? ''}`}
              className="text-base mr-6 text-tertiary-dark"
            >
              See all
            </Link>
          </div>
          <BuildingNeedAttentionTable />
        </div>
      </div>
      <CardContainer gap={6}>
        <div className="flex justify-between items-center">
          <h3 className="font-bold uppercase">ENERGY OVERVIEW 2024</h3>
          <div className="flex gap-4 items-center">
            <p>Showing</p>
            <SingleSelectInput
              options={sankeyDropdownOptions}
              value={selectedSankeyDropdownOption}
              onChange={(value) => {
                setSelectedSankeyDropdownOption(value as number);
              }}
              maxWidth="250px"
              placeholder="Select a date"
            />
          </div>
        </div>
        <div className="flex gap-2">
          <BuildingSankeyButton
            id={UtilityTypeEnum.ELECTRICITY as string}
            isSelected={selectedSankeyOption === UtilityTypeEnum.ELECTRICITY}
            handleSelection={setSelectedSankeyOption}
            utilityType={UtilityTypeEnum.ELECTRICITY}
            value={422.0}
            percentage={12}
            unit={UnitTypeEnum.KWH}
          />
          <BuildingSankeyButton
            id={UtilityTypeEnum.GAS as string}
            isSelected={selectedSankeyOption === UtilityTypeEnum.GAS}
            handleSelection={setSelectedSankeyOption}
            utilityType={UtilityTypeEnum.GAS}
            value={976.0}
            percentage={12}
            unit={UnitTypeEnum.KWH}
          />
          <BuildingSankeyButton
            id={UtilityTypeEnum.WATER as string}
            isSelected={selectedSankeyOption === UtilityTypeEnum.WATER}
            handleSelection={setSelectedSankeyOption}
            utilityType={UtilityTypeEnum.WATER}
            value={4.0}
            percentage={12}
            unit={UnitTypeEnum.CUBIC_METER}
          />
        </div>
        <BuildingSankeyChart id={id} nodeOnClick={handleSelectMeter} />
        <BuildingViewMeterChart meter={selectedMeter} />
        {selectedMeter?.id && (
          <div>
            <ChartButton
              buttonList={buttonList}
              activeChart={activeChart}
              handleActiveChart={setActiveChart}
              isLoading={false}
            />
            {activeChart === CHART_BUTTON_VALUES.MEASURED && (
              <MeasuredVsExpectedChart
                data={
                  selectedMeter?.id
                    ? (meterMockData.time_series as ChangeTS[])
                    : []
                }
              />
            )}
            {activeChart === CHART_BUTTON_VALUES.CUSUM && (
              <MeasuredVsExpectedChart
                data={
                  selectedMeter?.id
                    ? (meterMockData.time_series as ChangeTS[])
                    : []
                }
              />
            )}
          </div>
        )}
      </CardContainer>
    </div>
  );
};
export default BuildingViewPage;
