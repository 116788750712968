import { SwitchButton } from '@components/switchButton';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { type CellContext, createColumnHelper } from '@tanstack/react-table';
import axiosInstance from 'api/axios';
import { isAxiosError } from 'axios';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type Impact } from 'interfaces/impact/Impact.interface';
import queryClient from 'lib/queryClient';
import { type ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { convertToPercentageParsedString } from 'utils/formatters/number/numberUtils';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';
import { ImpactTableProgressBar } from '../components/ImpactTableProgressBar';

const useGetImpactTableColumn = ({
  createTableActions,
}: {
  createTableActions: (info: CellContext<any, any>) => ReactNode;
}): any => {
  const columnHelper = createColumnHelper<Impact>();
  const { t } = useTranslation();
  const { theme } = useTheme();

  const columns = [
    columnHelper.accessor('building_name', {
      header: () => 'Building name',
      cell: (info) => info.renderValue(),
      enableSorting: true,
      size: 150,
    }),
    columnHelper.accessor('building_function', {
      header: () => 'Function',
      cell: (info) => info.renderValue(),
      enableSorting: true,
    }),
    columnHelper.accessor('energy_intensity_kwh', {
      header: () => 'ENERGY INTENSITY 2023',
      cell: (info) => `${info.getValue() ?? 0} kWh/m²`,
      enableSorting: true,
      size: 150,
    }),
    columnHelper.accessor('energy_intensity_co2', {
      header: () => 'CO₂ INTENSITY 2023',
      cell: (info) => `${info.renderValue() as number} CO₂`,
      enableSorting: true,
      size: 150,
    }),
    columnHelper.accessor('data_completeness', {
      header: () => (
        <div className="flex items-center gap-2">
          <span>{t('impactPage.table.dataCompleteness')}</span>
          <TooltipIconComponent
            color={theme.colors['gray-50']}
            content={t('impactPage.table.tooltips.dataCompleteness')}
          />
        </div>
      ),
      cell: (info) => convertToPercentageParsedString(info.getValue()),
      enableSorting: true,
    }),
    columnHelper.accessor('target_savings_2050', {
      header: () => (
        <div className="flex items-center gap-2">
          <span>{t('impactPage.table.targetSavings2050')}</span>
          <TooltipIconComponent
            color={theme.colors['gray-50']}
            content={t('impactPage.table.tooltips.savingsTarget')}
          />
        </div>
      ),
      cell: (info) => {
        const data = info.row.original;
        return (
          <ImpactTableProgressBar
            minWidth={76}
            maxWidth={76}
            completed={data.target_savings}
            maxCompleted={data.target_savings_2050}
            layerCompleted={data.target_savings}
            tooltipProjects={data.projects}
          />
        );
      },
      size: 187,
      enableSorting: true,
    }),

    columnHelper.accessor('organizational_impact', {
      header: () => {
        return (
          <div className="flex items-center gap-2">
            <span>{t('impactPage.table.organizationalImpact')}</span>
            <TooltipIconComponent
              color={theme.colors['gray-50']}
              content={t('impactPage.table.tooltips.organizationalImpact')}
            />
          </div>
        );
      },
      cell: (info) => convertToPercentageParsedString(info.getValue()),
      enableSorting: true,
    }),

    columnHelper.accessor('protocol', {
      header: () => 'Organizational Protocol',
      cell: (info) => {
        const value = info.getValue();
        return value === 'UNSET' ? '-' : info.renderValue();
      },
      enableSorting: true,
    }),
    columnHelper.accessor('retain', {
      header: () => 'Retain',
      cell: (info) => {
        const impact = info.row.original;
        const value = info.getValue();

        const [checked, setChecked] = useState(value);

        return (
          <SwitchButton
            checked={checked}
            onChange={async (isChecked): Promise<void> => {
              try {
                await axiosInstance.patch(`organization_impact/${impact.id}`, {
                  retain: isChecked,
                });
                setChecked(isChecked);
                handleSuccess('Asset updated successfully');
                await queryClient.invalidateQueries({
                  queryKey: ['shortOrganizationImpactSummary'],
                });
              } catch (error) {
                if (isAxiosError(error)) {
                  handleError(error);
                }
              }
            }}
          />
        );
      },
      enableSorting: true,
    }),

    columnHelper.accessor('id', {
      header: '',
      enableSorting: false,
      id: 'action',
      cell: (info) => createTableActions(info),
      size: 15,
    }),
  ];

  return {
    columns,
  };
};
export default useGetImpactTableColumn;
