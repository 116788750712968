import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import { type EnervisionRootCauseResponse } from 'interfaces/change/Enervision.interface';
import {
  type UseMutationResult,
  type UseQueryResult,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { handleError } from 'utils/handleError/handleError';
import queryClient from 'lib/queryClient';

export const useFetchEnervisionRootCauses = (
  changeId?: string
): UseQueryResult<EnervisionRootCauseResponse> => {
  return useQuery<EnervisionRootCauseResponse>({
    queryKey: ['enervisionRootCauseList', changeId],
    queryFn: async () => {
      if (!changeId) return;
      const response = await axiosInstance.get(
        `change_detection/${changeId}/enervision_root_causes`
      );
      return response.data;
    },
  });
};

export const useUpdateEnervisionFeedback = (): UseMutationResult<
  any | null,
  Error | AxiosError,
  {
    enervision_id: number;
    change_id: string;
    positive_feedback: boolean;
    negative_feedback: boolean;
  }
> => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await axiosInstance.patch(
        `change_detection/enervision_feedback`,
        body
      );
      return response?.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      toast.success('Thank you for the feedback');
      await queryClient.invalidateQueries({
        queryKey: ['enervisionRootCauseList'],
      });
    },
  });
};

export const useSelectEnervisionRootCause = (): UseMutationResult<
  any | null,
  Error | AxiosError,
  { changeId: string; enervisionId: number }
> => {
  return useMutation({
    mutationFn: async ({ changeId, enervisionId }) => {
      const response = await axiosInstance.patch(
        `change_detection/select_enervision_root_cause`,
        { change_id: changeId, enervision_id: enervisionId }
      );
      return response?.data;
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
  });
};
