import { SearchBar } from '@components/inputs/SearchBar';
import { SelectInput } from '@components/inputs/SelectInput';
import { SwitchButton } from '@components/switchButton';
import {
  useListAssetFunctions,
  useListGroups,
} from 'api/assets/assetsServices';
import { useDebounce } from 'hooks/useDebounce';
import { CardContainer } from 'layout/Card';
import { type FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationImpactSummary } from './components/OrganizationImpactSummary';
import ImpactTable from './table/ImpactTable';

const ImpactPage: FC = () => {
  const [query, setQuery] = useState('');
  const [selectedFunction, setSelectedFunction] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<undefined | number>();
  const [isShowingCOIntensity, setIsShowingCOIntensity] = useState(false);
  const debouncedQuery = useDebounce(query, 300);
  const { t } = useTranslation();

  const { data: assetFunctionsList, isFetching: isFetchingAssetFunctions } =
    useListAssetFunctions();

  const { data: groupList, isFetching: isFetchingGroup } = useListGroups({
    perPage: 100,
  });

  const groupOptions = useMemo(
    () =>
      groupList?.pages?.flatMap(
        (page) =>
          page?.groups.map((group) => ({
            name: group.name,
            value: group.id,
          }))
      ) ?? [],
    [groupList]
  );

  const functionOptions = useMemo(() => {
    const functions = assetFunctionsList?.functions;
    if (!functions) return [];

    return functions.map((func) => ({ name: func, value: func }));
  }, [assetFunctionsList]);

  const handleFunctionOnChange = (
    assetFunction: string | number | undefined
  ): void => {
    if (typeof assetFunction === 'string') {
      setSelectedFunction(assetFunction);
    }
  };

  const handleGroupOnChange = (groupId: string | number | undefined): void => {
    if (typeof groupId === 'number') {
      setSelectedGroup(groupId);
    }
  };
  return (
    <div className="flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <h1>{t('common.strategy')}</h1>
        <SwitchButton
          label={t('impactPage.switch')}
          checked={isShowingCOIntensity}
          onChange={setIsShowingCOIntensity}
        />
      </div>
      <OrganizationImpactSummary />
      <CardContainer gap={6}>
        <h2 className="uppercase">{t('impactPage.buildingsPerformance')}</h2>
        <div className="flex flex-col gap-6">
          <div className="flex gap-6 items-center">
            <div className="w-full max-w-[340px]">
              <SearchBar
                onChange={(e) => {
                  setQuery(e.target.value);
                }}
              />
            </div>
            <SelectInput
              placeholder={t('common.function')}
              options={functionOptions}
              maxWidth="210px"
              isFetching={isFetchingAssetFunctions}
              onChange={handleFunctionOnChange}
              value={selectedFunction}
            />
            <SelectInput
              placeholder={t('common.groups')}
              maxWidth="210px"
              options={groupOptions}
              isFetching={isFetchingGroup}
              onChange={handleGroupOnChange}
              value={selectedGroup}
            />
          </div>
          <ImpactTable
            query={debouncedQuery}
            groupId={selectedGroup}
            buildingFunction={selectedFunction}
            isShowingCOIntensity={isShowingCOIntensity}
          />
        </div>
      </CardContainer>
    </div>
  );
};
export default ImpactPage;
