import styled from 'styled-components';
import tw from 'twin.macro';
import { isNumberOdd } from 'utils/numberUtils/numberUtils';

interface TableRowProps {
  $index: number;
  $isFetching?: boolean;
  $isVisualized?: boolean;
  $isHovered?: boolean;
  $enableVisualization?: boolean;
  $stripped?: boolean;
  $hoverColor?: string;
  $enableBorder?: boolean;
}

export const TableRow = styled.tr<TableRowProps>`
  ${(props) =>
    props.$enableBorder ? tw`border-b border-primary-lighter` : tw`border-b-0`}
  ${(props) =>
    !props.$isFetching && !props.$isVisualized && props.$enableVisualization
      ? tw`bg-primary-lighter`
      : props.$stripped && !isNumberOdd(props.$index)
      ? tw`bg-gray-10`
      : tw`bg-white`}
`;
