import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { getUtilityIcon } from 'utils/utilityTypeUtils/utilityTypeUtils';
import { numericFormatter } from 'react-number-format';
// import i18next from 'i18next';
import Tippy from '@tippyjs/react';
import { TableProgressBar } from 'pages/Management/ProjectListPage/components';

interface AnomaliesTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetMostImportantChangesTableColumns =
  (): AnomaliesTableColumnsReturnType => {
    const columnHelper = createColumnHelper<any>();

    const columns = [
      columnHelper.accessor('project_name', {
        header: () => 'Project name',
        cell: (info) => {
          const values = info.row.original;
          return (
            <Tippy content={info.getValue()} maxWidth={450}>
              <span className="flex items-center gap-2.5">
                <div className="fill-gray-50">
                  {getUtilityIcon(values.utility_type)}
                </div>
                <span className="max-w-[300px] desktop-l:max-w-[400px] block text-ellipsis overflow-hidden truncate">
                  {info.renderValue()}
                </span>
              </span>
            </Tippy>
          );
        },
        enableSorting: true,
        size: 150,
        maxSize: 200,
      }),
      columnHelper.accessor('distance_from_target', {
        id: 'distance_from_target',
        header: () => 'Target',
        cell: (info) => {
          const values = info.row.original;
          const completed = parseFloat(
            numericFormatter(((values?.savings_rel ?? 0) * 100).toString(), {
              decimalSeparator: ',',
              thousandSeparator: '.',
              decimalScale: 1,
            })
          );
          const maxCompleted = parseFloat(
            numericFormatter(((values?.savings_target ?? 0) * 100).toString(), {
              decimalSeparator: ',',
              thousandSeparator: '.',
              decimalScale: 1,
            })
          );
          return (
            <TableProgressBar
              completed={completed}
              maxCompleted={maxCompleted}
              maxWidth={120}
              enableNegativeBar={false}
            />
          );
        },
      }),
    ];

    return {
      columns,
    };
  };
export default useGetMostImportantChangesTableColumns;
