import { SadFaceIcon } from '@components/icons/FaceIcon';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import clsx from 'clsx';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
// import { type BuildingView } from 'interfaces/assets/Building.interface';
import { CardContainer } from 'layout/Card';
import { GRADE } from 'pages/BuildingViewPage/BuildingViewPage';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

interface BuildingOverallPerformanceProps {
  building?: any;
  isFetching?: boolean;
}

const BuildingOverallPerformance: FC<BuildingOverallPerformanceProps> = ({
  building,
  isFetching,
}) => {
  const { t } = useTranslation();
  return (
    <div className="flex flex-col gap-2.5 flex-1 h-full">
      <h3 className="font-bold uppercase">Overall performance</h3>
      <CardContainer
        style={{ flex: 1, justifyContent: 'space-between' }}
        gap={4}
      >
        <OverallPerformanceItem
          title={'Energy intensity 2023'}
          value={building?.asset_impacts[0].energy_intensity_kwh}
          unit={BuildingUnitEnum.kwh}
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.energyIntensity')}
          effectiveness={GRADE.POSITIVE}
        />
        <OverallPerformanceItem
          title={'Renewable Energy'}
          value={12}
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.renewableEnergy')}
          effectiveness={GRADE.NEGATIVE}
        />
        <OverallPerformanceItem
          title={'Savings target 2050'}
          value={building?.asset_impacts[0].target_savings_2050}
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.savingsTarget')}
          effectiveness={GRADE.POSITIVE}
        />
        <OverallPerformanceItem
          title={'Organizational impact'}
          value={building?.asset_impacts[0].organizational_impact}
          isFetching={isFetching}
          tooltipContent={t('buildingView.tooltips.organizationalImpact')}
          effectiveness={GRADE.NEGATIVE}
        />
      </CardContainer>
    </div>
  );
};

enum BuildingUnitEnum {
  percentage = '%',
  kwh = 'kWh/m²',
}

const OverallPerformanceItem: FC<{
  title: string;
  value?: number;
  unit?: BuildingUnitEnum;
  isFetching?: boolean;
  tooltipContent: string;
  effectiveness?: string;
}> = ({
  title,
  value,
  unit = BuildingUnitEnum.percentage,
  isFetching,
  tooltipContent,
  effectiveness,
}) => {
  const { theme } = useTheme();
  return (
    <div
      className={clsx(
        'flex flex-col gap-2 px-4 py-2 bg-gray-10 rounded-lg border ',
        effectiveness === GRADE.POSITIVE && 'border-success',
        effectiveness === GRADE.NEGATIVE && 'border-error',
        effectiveness === undefined && 'border-gray-10'
      )}
    >
      {isFetching ? (
        <Skeleton height={24} containerClassName="flex-1" />
      ) : (
        <div className="flex justify-between items-center">
          <p className="text-base">{title}</p>
          <TooltipIconComponent
            content={tooltipContent}
            color={theme.colors['gray-45']}
          />
        </div>
      )}

      <div className="flex justify-between items-center">
        {isFetching ? (
          <Skeleton height={30} containerClassName="flex-1" />
        ) : (
          <p className="text-2xl font-bold" style={{ fontSize: 28 }}>
            {value ?? 0}
            {unit === BuildingUnitEnum.kwh ? (
              <span className="text-base">{` ${BuildingUnitEnum.kwh}`}</span>
            ) : (
              '%'
            )}
          </p>
        )}

        {isFetching ? (
          <></>
        ) : (
          effectiveness === GRADE.NEGATIVE && (
            <SadFaceIcon className="mr-[-5px]" />
          )
        )}
      </div>
    </div>
  );
};
export default BuildingOverallPerformance;
