import styled from 'styled-components';
import tw from 'twin.macro';

interface SankeyButtonProps {
  $isSelected: boolean;
}

export const SankeyButton = styled.div<SankeyButtonProps>`
  ${tw`rounded-lg flex flex-col py-2 px-4 gap-4 w-max cursor-pointer`}
  ${({ $isSelected }) =>
    $isSelected ? tw`bg-primary-lighter border border-primary` : tw`bg-gray-10`}
`;
