import { type FC, type SVGProps } from 'react';

const HappyFaceIcon: FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path
      d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM6 11H14C14 13.2091 12.2091 15 10 15C7.79086 15 6 13.2091 6 11ZM6 9C5.17157 9 4.5 8.3284 4.5 7.5C4.5 6.67157 5.17157 6 6 6C6.82843 6 7.5 6.67157 7.5 7.5C7.5 8.3284 6.82843 9 6 9ZM14 9C13.1716 9 12.5 8.3284 12.5 7.5C12.5 6.67157 13.1716 6 14 6C14.8284 6 15.5 6.67157 15.5 7.5C15.5 8.3284 14.8284 9 14 9Z"
      fill="#23B44C"
    />
  </svg>
);
export default HappyFaceIcon;
