import {
  type CSSProperties,
  type ReactElement,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  type ColumnDef,
  type SortingState,
  type TableState,
  type Updater,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { type InfiniteQueryObserverResult } from 'react-query';
import Skeleton from 'react-loading-skeleton';
import { TableRow } from './styled';
import clsx from 'clsx';
interface TableProps<T> {
  isFetching: boolean;
  data: T[];
  columns: ColumnDef<any, any>[];
  state?: Partial<TableState>;
  totalDBRowCount?: number;
  rowEstimateSize?: number;
  enableRowSelection?: boolean;
  isFetchingNextPage?: boolean;
  enableLastColumnClick?: boolean;
  manualSorting?: boolean;
  hoveredId?: number | undefined;
  enableVisualizationRequest?: boolean;
  stripped?: boolean;
  disableHeader?: boolean;
  minHeight?: number;
  onRowHover?: (value: number | undefined) => void;
  handleSortChange?: (item: Updater<SortingState>) => SortingState | undefined;
  fetchNextPage?: () => Promise<InfiniteQueryObserverResult<unknown, unknown>>;
  onClickRow?: (object: T) => void;
  hoverColor?: string;
  enableRowBorder?: boolean;
  tableRowStyle?: CSSProperties;
  enableRowRoundedBorder?: boolean;
  skeletonAmountWhenLoading?: number;
}

function IconTable<T>({
  data,
  isFetching,
  columns,
  enableRowSelection,
  onClickRow,
  state,
  isFetchingNextPage,
  enableLastColumnClick = false,
  handleSortChange,
  hoveredId,
  stripped,
  onRowHover,
  enableVisualizationRequest = false,
  disableHeader = false,
  manualSorting = false,
  minHeight = 300,
  hoverColor,
  enableRowBorder = true,
  tableRowStyle,
  enableRowRoundedBorder,
  skeletonAmountWhenLoading = 5,
}: TableProps<T>): ReactElement {
  const [sorting, setSorting] = useState<SortingState>([]);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const isFetchingOrQuerying = useMemo(
    () => isFetching && !isFetchingNextPage,
    [isFetching, isFetchingNextPage]
  );

  const tableData = useMemo(
    () =>
      isFetchingOrQuerying ? Array(skeletonAmountWhenLoading).fill({}) : data,
    [isFetchingOrQuerying, data]
  );

  const tableColumns = useMemo(
    () =>
      isFetchingOrQuerying
        ? columns.map((column) => ({
            ...column,
            cell: () => <Skeleton />,
          }))
        : columns,
    [isFetchingOrQuerying, columns]
  );

  const onSortingChange = (item: Updater<SortingState>): void => {
    const sort = handleSortChange?.(item);
    setSorting(sort ?? item);
  };

  const table = useReactTable({
    data: tableData,
    columns: tableColumns,
    state: {
      sorting,
      ...state,
    },
    enableSorting: false,
    onSortingChange: (sortingUpdater) => {
      onSortingChange(sortingUpdater);
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    enableRowSelection,
    defaultColumn: {
      minSize: 0,
      size: Number.MAX_SAFE_INTEGER,
      maxSize: Number.MAX_SAFE_INTEGER,
    },
  });

  const { rows } = table.getRowModel();

  return (
    <div
      className="rounded-lg w-full max-h-[700px] overflow-y-scroll block relative bg-white"
      style={{ minHeight }}
      ref={tableContainerRef}
    >
      <table className="w-full">
        <thead className="sticky top-0 z-20">
          {table.getHeaderGroups().map(
            (headerGroup) =>
              !disableHeader && (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      header.getContext() && (
                        <th
                          key={header.id}
                          className={clsx(
                            `px-3 py-3 text-start text-xs uppercase`,
                            enableRowBorder
                              ? 'border-b border-primary-lighter'
                              : 'border-none'
                          )}
                        >
                          <div
                            {...{
                              className: clsx(
                                header.column.getCanSort() &&
                                  'cursor-pointer select-none flex gap-2 items-center'
                              ),
                              onClick: header.column.getToggleSortingHandler(),
                              style: {
                                width:
                                  header.getSize() === Number.MAX_SAFE_INTEGER
                                    ? 'auto'
                                    : header.getSize(),
                              },
                            }}
                          >
                            {header.isPlaceholder
                              ? null
                              : flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}

                            {header.column.getCanSort() &&
                            !header.column.getIsSorted() ? (
                              <IoIosArrowDown />
                            ) : null}

                            {{
                              asc: <IoIosArrowUp />,
                              desc: <IoIosArrowDown />,
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      )
                    );
                  })}
                </tr>
              )
          )}
        </thead>
        <tbody>
          {rows.map((row, index) => {
            const isVisualized = row.original?.visualized;
            return (
              <TableRow
                style={{
                  backgroundColor:
                    !isFetching && hoveredId === row.original.id
                      ? hoverColor
                      : undefined,
                  ...tableRowStyle,
                }}
                key={row.id}
                $isFetching={isFetching}
                $enableVisualization={enableVisualizationRequest}
                $isVisualized={isVisualized}
                $stripped={stripped}
                $enableBorder={enableRowBorder}
                $index={index}
                $hoverColor={hoverColor}
                $isHovered={hoveredId === row.original.id}
                onMouseEnter={() => onRowHover?.(row.original.id)}
                onMouseLeave={() => onRowHover?.(undefined)}
              >
                {row.getVisibleCells().map((cell: any, index: number) => {
                  const cellContent = flexRender(
                    cell.column.columnDef.cell,
                    cell.getContext()
                  );
                  const roundedLeft = index === 0 && enableRowRoundedBorder;
                  const roundedRight =
                    index + 1 === row.getVisibleCells().length &&
                    enableRowRoundedBorder;

                  return (
                    <td
                      key={cell.id}
                      className={clsx(
                        onClickRow && 'cursor-pointer',
                        'py-3 px-3',
                        roundedLeft && 'rounded-l-lg',
                        roundedRight && 'rounded-r-lg'
                      )}
                      onClick={() => {
                        if (
                          index !== row.getVisibleCells().length - 1 ||
                          (index === row.getVisibleCells().length - 1 &&
                            enableLastColumnClick)
                        ) {
                          const rowData = cell.row.original;
                          if (!isFetching && onClickRow) {
                            onClickRow(rowData);
                          }
                        }
                      }}
                      style={{
                        width:
                          cell.column.getSize() === Number.MAX_SAFE_INTEGER
                            ? 'auto'
                            : cell.column.getSize(),
                      }}
                    >
                      <span>{cellContent}</span>

                      {/* <Tippy content={cellContentElement}>
                      </Tippy> */}
                    </td>
                  );
                })}
              </TableRow>
            );
          })}
          {isFetchingNextPage && (
            <tr>
              {table.getVisibleFlatColumns()?.map((column) => (
                <td
                  key={column.id}
                  className={` py-6 px-7 ${
                    enableRowBorder
                      ? 'border-b border-primary-lighter'
                      : 'border-none'
                  }`}
                >
                  <Skeleton />
                </td>
              ))}
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default IconTable;
