import {
  HappyFaceIcon,
  SadFaceIcon,
  SmileFaceIcon,
} from '@components/icons/FaceIcon';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC, useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

export enum AttentionLevels {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}
interface BuildingAttentionLevelProps {
  attention: AttentionLevels;
  isFetching?: boolean;
}
const BuildingAttentionLevel: FC<BuildingAttentionLevelProps> = ({
  attention,
  isFetching,
}) => {
  const { theme } = useTheme();
  const attentionSpecificLayout = useMemo(() => {
    if (attention === AttentionLevels.HIGH) {
      return {
        fontColor: theme.colors.error,
        bgColor: theme.colors['status-bg'].error,
        text: 'Attention needed',
        icon: <SadFaceIcon />,
      };
    } else if (attention === AttentionLevels.MEDIUM) {
      return {
        fontColor: theme.colors.attention,
        bgColor: 'rgba(239, 131, 31, 0.10)',
        text: 'Some action needed',
        icon: <SmileFaceIcon />,
      };
    } else if (attention === AttentionLevels.LOW) {
      return {
        fontColor: theme.colors.status.solved,
        bgColor: '#23B44C1A',
        text: 'No action required',
        icon: <HappyFaceIcon />,
      };
    }
  }, [attention]);
  return isFetching ? (
    <Skeleton height={48} width={180} />
  ) : (
    <div
      className="flex justify-between items-center gap-4 px-4 py-2.5 w-fit rounded-lg"
      style={{ backgroundColor: attentionSpecificLayout?.bgColor }}
    >
      {attentionSpecificLayout?.icon}
      <p
        className="text-lg"
        style={{ color: attentionSpecificLayout?.fontColor }}
      >
        {attentionSpecificLayout?.text}
      </p>
      <TooltipIconComponent
        content="TBD"
        color={attentionSpecificLayout?.fontColor}
      />
    </div>
  );
};
export default BuildingAttentionLevel;
