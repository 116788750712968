import { type FC, type HTMLAttributes, type ReactNode } from 'react';

interface CardContainerProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
  gap?: number;
  padding?: number;
}

const CardContainer: FC<CardContainerProps> = ({ children, gap, ...props }) => {
  return (
    <div
      className={`flex flex-col p-6 shadow-card-container rounded-lg w-full bg-white gap-${
        gap ?? 0
      }`}
      {...props}
    >
      {children}
    </div>
  );
};
export default CardContainer;
