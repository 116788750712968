import {
  type InfiniteData,
  type UseInfiniteQueryResult,
  type UseMutationResult,
  type UseQueryResult,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from '@tanstack/react-query';
import axiosInstance from 'api/axios';
import { type AxiosError } from 'axios';
import {
  type ShortOrganizationImpactSummary,
  type impactListResponse,
} from 'interfaces/impact/Impact.interface';
import queryClient from 'lib/queryClient';
import { handleError } from 'utils/handleError/handleError';
import { handleSuccess } from 'utils/handleSuccess/handleSuccess';

export const useListOrganizationImpact = ({
  query,
  buildingFunction,
  groupId,
  orderBy = '',
}: {
  query?: string;
  buildingFunction?: string;
  groupId?: number;
  orderBy?: string;
}): UseInfiniteQueryResult<InfiniteData<impactListResponse>> => {
  return useInfiniteQuery<impactListResponse>({
    queryKey: [
      'organizationImpactList',
      query,
      buildingFunction,
      groupId,
      orderBy,
    ],
    queryFn: async () => {
      const params = {
        query,
        group_id: groupId,
        building_function: buildingFunction,
        order_by: orderBy,
      };

      if (!params.group_id) {
        delete params.group_id;
      }
      if (!params.building_function) {
        delete params.building_function;
      }
      const response = await axiosInstance.get(`organization_impact/filter`, {
        params,
      });
      return response?.data;
    },
    initialPageParam: 0,
    getNextPageParam: (_lastGroup, groups) => {
      if (_lastGroup?.hasNextPage) {
        return _lastGroup.nextPage;
      }
    },
  });
};

export const useGetShortOrganizationImpactSummary =
  (): UseQueryResult<ShortOrganizationImpactSummary> => {
    return useQuery<ShortOrganizationImpactSummary>({
      queryKey: ['shortOrganizationImpactSummary'],
      queryFn: async () => {
        const response = await axiosInstance.get(`organization_impact/summary`);
        return response?.data;
      },
    });
  };

export const useUpdateImpact = (
  id?: number
): UseMutationResult<
  void,
  Error | AxiosError,
  { retain?: boolean; savingsTarget?: number }
> => {
  return useMutation({
    mutationFn: async ({ retain, savingsTarget }) => {
      if (!id) return;

      const payload = { retain, target_savings_2050: savingsTarget };
      if (!payload.retain) {
        delete payload.retain;
      }
      if (!payload.target_savings_2050 && payload.target_savings_2050 !== 0) {
        delete payload.target_savings_2050;
      }
      await axiosInstance.patch(`organization_impact/${id}`, {
        ...payload,
      });
    },
    onError: (error: Error | AxiosError) => {
      handleError(error);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['shortOrganizationImpactSummary'],
      });
      await queryClient.invalidateQueries({
        queryKey: ['organizationImpactList'],
      });
      handleSuccess('Asset updated successfully');
    },
  });
};

export const useGetOrganizationImpactSummary = (
  id?: string
): UseQueryResult<ShortOrganizationImpactSummary> => {
  return useQuery<ShortOrganizationImpactSummary>({
    queryKey: ['shortOrganizationImpactSummary', id],
    queryFn: async () => {
      if (!id) return;
      const response = await axiosInstance.get(`organization_impact/${id}`);
      return response?.data;
    },
  });
};
