import { IconTable } from '@components/table/IconTable';
import TooltipIconComponent from '@components/TooltipIconComponent/TooltipIconComponent';
import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { CardContainer } from 'layout/Card';
import { type FC, useState } from 'react';
import useGetMostImportantChangesTableColumns from './useGetBuildingNeedAttentionColumns';
import { GRADE } from 'pages/BuildingViewPage/BuildingViewPage';
import clsx from 'clsx';
import { SadFaceIcon } from '@components/icons/FaceIcon';

const BuildingNeedAttentionTable: FC = () => {
  const [hoveredId, setHoveredId] = useState<undefined | number>(undefined);
  const { columns } = useGetMostImportantChangesTableColumns();
  const { theme } = useTheme();
  const effectiveness: string = GRADE.NEGATIVE;

  return (
    <div
      className={clsx(
        'rounded-lg',
        effectiveness === GRADE.POSITIVE && 'border border-success',
        effectiveness === GRADE.NEGATIVE && 'border border-error'
      )}
    >
      <CardContainer>
        <div className="flex items-center py-2 justify-between">
          <div className="flex items-center gap-4">
            <h3 className="font-bold text-base">Need attention</h3>
            <TooltipIconComponent content="TBD" />
          </div>
          {effectiveness === GRADE.NEGATIVE && <SadFaceIcon />}
        </div>
        <IconTable
          minHeight={200}
          skeletonAmountWhenLoading={3}
          isFetching={false}
          enableRowBorder={false}
          stripped={false}
          onRowHover={(id) => {
            setHoveredId(id);
          }}
          hoveredId={hoveredId}
          hoverColor={theme.colors['tertiary-lighter']}
          enableRowRoundedBorder={true}
          data={[
            {
              id: 1,
              utility_type: 'ELECTRICITY',
              project_name: 'Project 1',
              savings_target: 1,
              savings_rel: 0.2,
            },
          ]}
          columns={columns}
        />
      </CardContainer>
    </div>
  );
};
export default BuildingNeedAttentionTable;
