import MapContainer, { MAP_STYLES } from '@components/map/Map';
import { type FC, useMemo, useRef } from 'react';
import { type MapRef, Marker } from 'react-map-gl';
import Skeleton from 'react-loading-skeleton';

interface BuildingMapProps {
  lat?: number;
  long?: number;
  isFetching?: boolean;
}

const BuildingMap: FC<BuildingMapProps> = ({ lat, long, isFetching }) => {
  const mapRef = useRef<MapRef | null>(null);

  const coordinates = useMemo(() => {
    return [[long, lat]];
  }, [lat, long]);

  return (
    <div className="flex flex-1">
      {isFetching ? (
        <Skeleton height={388} containerClassName="flex-1" />
      ) : (
        <MapContainer
          ref={mapRef}
          coordinates={coordinates}
          height={388}
          mapStyle={MAP_STYLES.satellite}
          fitBounds={false}
          initialViewState={{
            latitude: lat ?? 0,
            longitude: long ?? 0,
            zoom: 16,
          }}
        >
          {long && lat && (
            <Marker
              longitude={long}
              latitude={lat}
              anchor="bottom"
              color="red"
            />
          )}
        </MapContainer>
      )}
    </div>
  );
};
export default BuildingMap;
