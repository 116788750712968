import { useTheme } from 'context/ThemeProvider/ThemeProvider';
import { type FC } from 'react';
import Skeleton from 'react-loading-skeleton';

export const BookMarkTag: FC<{ isFetching?: boolean }> = ({ isFetching }) => {
  const { theme } = useTheme();
  const certificateSquareClassName =
    'w-[53px] h-[36px] z-[2] relative flex items-center justify-center text-2xl text-white';

  const certificateTipClassName =
    'absolute h-[36px] transform rotate-45 top-0 left-[-10px] w-[36px] z-[1]';

  return (
    <div className="absolute right-[-24px] overflow-y-clip overflow-x-visible">
      {isFetching ? (
        <Skeleton
          containerClassName={certificateSquareClassName}
          height={36}
          width={53}
        />
      ) : (
        <div
          className={`${certificateSquareClassName}`}
          style={{ backgroundColor: theme.colors.status.solved }}
        >
          A
        </div>
      )}
      {isFetching ? (
        <Skeleton
          containerClassName={certificateTipClassName}
          height={36}
          width={36}
        />
      ) : (
        <div
          className={`${certificateTipClassName}`}
          style={{ backgroundColor: theme.colors.status.solved }}
        />
      )}
    </div>
  );
};
export default BookMarkTag;
