import { type ColumnDef, createColumnHelper } from '@tanstack/react-table';
import { getUtilityIcon } from 'utils/utilityTypeUtils/utilityTypeUtils';
import { convertToReadableDate } from 'utils/dateUtils/dateUtils';
import { numericFormatter } from 'react-number-format';
import i18next from 'i18next';
import Tippy from '@tippyjs/react';

interface AnomaliesTableColumnsReturnType {
  columns: ColumnDef<any, any>[];
}

const useGetMostImportantChangesTableColumns =
  (): AnomaliesTableColumnsReturnType => {
    const columnHelper = createColumnHelper<any>();

    const columns = [
      columnHelper.accessor('meter_name', {
        header: () => i18next.t('dashboardPage.table.meterName'),
        cell: (info) => {
          const values = info.row.original;
          return (
            <Tippy content={info.getValue()} maxWidth={450}>
              <span className="flex items-center gap-2.5">
                <div className="fill-gray-50">
                  {getUtilityIcon(values.utility_type)}
                </div>
                <span className="max-w-[300px] desktop-l:max-w-[400px] block text-ellipsis overflow-hidden truncate">
                  {info.renderValue()}
                </span>
              </span>
            </Tippy>
          );
        },
        enableSorting: true,
        size: 150,
        maxSize: 200,
      }),
      columnHelper.accessor('moment_change', {
        header: () => i18next.t('dashboardPage.table.momentOfChange'),
        cell: (info) => (
          <span>{convertToReadableDate(info.renderValue())}</span>
        ),
      }),
      columnHelper.accessor('impact_average_diff_euro', {
        id: 'impact_unit',
        header: () => (
          <div className="flex items-center gap-2">
            <span>{i18next.t('dashboardPage.table.impactPerDay')}</span>
          </div>
        ),
        cell: (info) => {
          return `${numericFormatter((info.getValue() ?? 0).toFixed(2), {
            decimalSeparator: ',',
            thousandSeparator: '.',
            decimalScale: 2,
          })} €`;
        },
      }),
    ];

    return {
      columns,
    };
  };
export default useGetMostImportantChangesTableColumns;
