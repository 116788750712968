import { Table } from '@components/table/Table';
import { TableActions } from '@components/table/tableActions';
import { type CellContext } from '@tanstack/react-table';
import { useListOrganizationImpact } from 'api/impact/impactServices';
import useDisclosure from 'hooks/useDisclosure';
import { t } from 'i18next';
import { type FC, type ReactNode, useEffect, useMemo, useState } from 'react';
import { useGetImpactTableColumn } from '../hooks';
import { EditAssetImpactModal } from '../modal/EditAssetImpactModal';
import { useServerSideTableSorting } from 'hooks/useServerSideTableSorting';

interface ImpactTableProps {
  query: string;
  buildingFunction?: string;
  groupId?: number;
  isShowingCOIntensity: boolean;
}

const ImpactTable: FC<ImpactTableProps> = ({
  query,
  buildingFunction,
  groupId,
  isShowingCOIntensity,
}) => {
  const [selectedAsset, setSelectedAsset] = useState<number | undefined>();
  const [savingsTarget, setSavingsTarget] = useState(0);
  const [columnVisibility, setColumnVisibility] = useState({
    energy_intensity_co2: false,
    energy_intensity_kwh: true,
  });
  const { isOpen, handleClose, handleOpen } = useDisclosure();

  const { orderBy, handleSortChange } = useServerSideTableSorting();
  const {
    data: impactList,
    isFetching,
    fetchNextPage,
  } = useListOrganizationImpact({
    query,
    buildingFunction,
    groupId,
    orderBy,
  });

  const createTableActions = (info: CellContext<any, any>): ReactNode => {
    const id: number = info?.row?.original?.id;
    const savings = info?.row?.original?.target_savings_2050;
    const actions = [
      {
        name: t('common.actions.edit'),
        onClick: () => {
          setSelectedAsset(id);
          setSavingsTarget(savings);
          handleOpen();
        },
      },
    ];
    return <TableActions key={`action-${id}`} actions={actions} />;
  };

  const { columns } = useGetImpactTableColumn({ createTableActions });

  const organizationImpactList = useMemo(() => {
    if (impactList) {
      return impactList.pages.flatMap((page) => page.asset_impacts);
    }
    return [];
  }, [impactList]);

  useEffect(() => {
    if (isShowingCOIntensity) {
      setColumnVisibility({
        energy_intensity_co2: true,
        energy_intensity_kwh: false,
      });
    } else {
      setColumnVisibility({
        energy_intensity_co2: false,
        energy_intensity_kwh: true,
      });
    }
  }, [isShowingCOIntensity]);

  return (
    <>
      <Table
        isFetching={isFetching}
        data={organizationImpactList}
        columns={columns}
        fetchNextPage={fetchNextPage}
        totalDBRowCount={organizationImpactList.length ?? 0}
        rowEstimateSize={75}
        state={{ columnVisibility }}
        handleSortChange={handleSortChange}
        manualSorting={true}
      />
      <EditAssetImpactModal
        isOpen={isOpen}
        handleClose={handleClose}
        selectedId={selectedAsset}
        savingsTarget={savingsTarget}
      />
    </>
  );
};
export default ImpactTable;
